export const B2CCFProfileContainer = () => import('./b2c-cf-profile-container');
export const B2CCFProfileInformation = () => import('./b2c-cf-profile-information');
export const B2CCFProfileLevel = () => import('./b2c-cf-profile-level');
export const B2CCFProfileMyDetails = () => import('./b2c-cf-profile-my-details');
export const B2CCFProfileMyDetailsAddress = () => import('./b2c-cf-profile-my-details-address');
export const B2CCFProfileOrderHistory = () => import('./b2c-cf-profile-order-history');
export const B2CCFProfileMovimientos = () => import('./b2c-cf-profile-movimientos');
export const B2CCFOrderDetails = () => import('./b2c-cf-order-details');
export const B2CCFSubscriptionHistory = () => import('./b2c-cf-subscription-history');
export const B2CCFSubscriptionDetails = () => import('./b2c-cf-subscription-details');
