export const B2CCFProductDetailsContainer = () => import('./b2c-cf-product-details-container');
export const B2CCFProductImageViewer = () => import('./b2c-cf-product-image-viewer');
export const B2CCFProductName = () => import('./b2c-cf-product-name');
export const B2CCFProductPrice = () => import('./b2c-cf-product-price');
export const B2CCFProductDescription = () => import('./b2c-cf-product-description');
export const B2CCFProductQuantitySelect = () => import('./b2c-cf-product-quantity-select');
export const B2CCFProductPromotionBanner = () => import('./b2c-cf-product-promotion-banner');
export const B2CCFProductAddToCart = () => import('./b2c-cf-product-add-to-cart');
export const B2CCFProductBreadcrumb = () => import('./b2c-cf-product-breadcrumb');
export const B2CCFProductSubscription = () => import('./b2c-cf-product-subscription');
export const B2CCFSubscriptionQAContainer = () => import('./b2c-cf-subscription-qa-container');
export const B2CCFSubscriptionQA = () => import('./b2c-cf-subscription-qa');
