export const B2CCFBannerCafeIdeal = () => import('./b2c-cf-banner-cafe-ideal');
export const B2CCFSpotlightCafeIdealContainer = () => import('./b2c-cf-spotlight-cafe-ideal-container');
export const B2CCFSpotlightCafeIdealCard = () => import('./b2c-cf-spotlight-cafe-ideal-card');
export const B2CCFCafeIdealPlansContainer = () => import('./b2c-cf-cafe-ideal-plans-container');
export const B2CCFCafeIdealPlansCard = () => import('./b2c-cf-cafe-ideal-plans-card');
export const B2CCFCafeIdealQuizRedirect = () => import('./b2c-cf-banner-cafe-quiz-redirect');
export const B2CCFCafeIdealQuiz = () => import('./b2c-cf-cafe-ideal-quiz');
export const B2CCFCafeIdealResultadoContainer = () => import('./b2c-cf-cafe-ideal-resultado-container');
export const B2CCFCafeIdealResultado = () => import('./b2c-cf-cafe-ideal-resultado');
export const B2CCFCafeIdealResultadoMosaic = () => import('./b2c-cf-banner-cafe-ideal-resultado-mosaic');
