export const B2BCFRSFeaturedContentBanner = () => import('./b2b-cf-rs-featured-content-banner');
export const B2BCFRSStampSpotlight = () => import('./b2b-cf-rs-stamp-spotlight');
export const B2BCFRSStampContent = () => import('./b2b-cf-rs-stamp-content');
export const B2BCFRSCardSpotlight = () => import('./b2b-cf-rs-card-spotlight');
export const B2BCFRSCardContent = () => import('./b2b-cf-rs-card-content');
export const B2BCFRSDownloadLink = () => import('./b2b-cf-rs-download-link');
export const B2BCFRSMiniBannerWithContent = () => import('./b2b-cf-rs-mini-banner-with-content');
export const B2BCFRSCommunityDescription = () => import('./b2b-cf-rs-community-description');
export const B2BCFRSBannerWithContent = () => import('./b2b-cf-rs-banner-with-content');
export const B2BCFRSBannerFull = () => import('./b2b-cf-rs-banner-full');
export const B2BCFRSSustainableCardSpotlight = () => import('./b2b-cf-rs-sustainable-card-spotlight');
export const B2BCFRSSustainableCardContent = () => import('./b2b-cf-rs-sustainable-card-content');
