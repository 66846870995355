export const B2BCFMBFeaturedBanner = () => import('./b2b-cf-mb-featured-banner');
export const B2BCFMBDescription = () => import('./b2b-cf-mb-description');
export const B2BCFMBCardWithContent = () => import('./b2b-cf-mb-card-with-content');
export const B2BCFMBCardWithContentContainer = () => import('./b2b-cf-mb-card-with-content-container');
export const B2BCFMBCardWithContentContainer2 = () => import('./b2b-cf-mb-card-with-content-container-2');
export const B2BCFMBCardWithContent2 = () => import('./b2b-cf-mb-card-with-content-2');
export const B2BCFMBRepresentativeContact = () => import('./b2b-cf-mb-representative-contact');
export const B2BCFMBShelf = () => import('./b2b-cf-mb-shelf');
export const B2BCFMBServicesCarrousel = () => import('./b2b-cf-mb-services-carrousel');
export const B2BCFMBServicesCard = () => import('./b2b-cf-mb-services-card');
export const B2BCFMBSKUShelf = () => import('./b2b-cf-mb-sku-shelf');
