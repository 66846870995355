export const B2BCFHeader = () => import('./b2b-cf-header');
export const B2BCFBannerSpotlight = () => import('./b2b-cf-banner-spotlight');
export const B2BCFBannerWithContent = () => import('./b2b-cf-banner-with-content');
export const B2BCFBreacrumb = () => import('./b2b-cf-breadcrumb');
export const B2BCFCardSpotlight = () => import('./b2b-cf-card-spotlight');
export const B2BCFCart = () => import('./b2b-cf-cart');
export const B2BCFFooter = () => import('./b2b-cf-footer');
export const B2BCFCheckout = () => import('./b2b-cf-checkout');
export const B2BCFFeaturedBanner = () => import('./b2b-cf-featured-banner');
export const B2BCFFeaturedBannerContainer = () => import('./b2b-cf-featured-banner-container');
export const B2BCFForgotPassword = () => import('./b2b-cf-forgot-password');
export const B2BCFLoginMobile = () => import('./b2b-cf-login-mobile');
export const B2BCFNotFound = () => import('./b2b-cf-not-found');
export const B2BCFResetPassword = () => import('./b2b-cf-reset-password');
export const B2BCFProfile = () => import('./b2b-cf-profile');
export const B2BCFProductListing = () => import('./b2b-cf-product-listing');
export const B2BCFProductDetails = () => import('./b2b-cf-product-details');
export const B2BCFOrderHistory = () => import('./b2b-cf-order-history');
export const B2BCFOrderDetails = () => import('./b2b-cf-order-details');
export const B2BCFOrderConfirmation = () => import('./b2b-cf-order-confirmation');
export const B2BCFRegistration = () => import('./b2b-cf-registration');
export const B2BCFLPBanner = () => import('./b2b-cf-lp-banner');
export const B2BCFBannerWithImage = () => import('./b2b-cf-banner-with-image');
export * from './b2b-cf-mb';
export * from './b2b-cf-rs';
export * from './b2b-cf-st';
export * from './b2b-cf-sala-exhibicion';
