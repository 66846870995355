export const B2CCFHeader = () => import('./b2c-cf-header');
export const B2CCFHeaderPrimary = () => import('./b2c-cf-header-primary');
export const B2CCFProfileRegistration = () => import('./b2c-cf-profile-registration');
export const B2CCFHeaderCheckout = () => import('./b2c-cf-header-checkout');
export const B2CCFLoginMobile = () => import('./b2c-cf-login-mobile');
export const B2CCFResetPassword = () => import('./b2c-cf-reset-password');
export const B2CCFBannerSpotligthPrimary = () => import('./b2c-cf-banner-spotligth-primary');
export const B2CCFCardSpotlightPrimary = () => import('./b2c-cf-card-spotlight-primary');
export const B2CCFBannerCorporatePrimary = () => import('./b2c-cf-banner-corporate-primary');
export const B2CCFBannerSpotlight = () => import('./b2c-cf-banner-spotlight');
export const B2CCFFooterPrincipal = () => import('./b2c-cf-footer-principal');
export const B2CCFFooterSecondary = () => import('./b2c-cf-footer-secondary');
export const B2CCFProductShelf = () => import('./b2c-cf-product-shelf');
export const B2CCFProductListing = () => import('./b2c-cf-product-listing');
export const B2CCFBannerWithContent = () => import('./b2c-cf-banner-with-content');
export const B2CCFMosaicBanner = () => import('./b2c-cf-mosaic-banner');
export const B2CCFBannerPrimaryMosaic = () => import('./b2c-cf-banner-primary-mosaic');
export const B2CCFBannerSecondaryMosaic = () => import('./b2c-cf-banner-secondary-mosaic');
export const B2CCFBannerIconsMosaic = () => import('./b2c-cf-banner-icons-mosaic');
export const B2CCFBannerNewsletter = () => import('./b2c-cf-banner-newsletter');
export * from './b2c-cf-cart-widgets';
export * from './b2c-cf-product-details';
export const B2CCFOrderSummary = () => import('./b2c-cf-order-summary');
export const B2CCFQuickviewContainer = () => import('./b2c-cf-quickview-container');
export const B2CCFSpotlightCardContainer = () => import('./b2c-cf-spotlight-card-container');
export const B2CCFCardWithContent = () => import('./b2c-cf-card-with-content');
export const B2CCFBannerfull = () => import('./b2c-cf-bannerfull');
export const B2CCFRecommendedProductShelf = () => import('./b2c-cf-recommended-product-shelf');
export const B2CCFLongDescription = () => import('./b2c-cf-long-description');
export const B2CCFNotFound = () => import('./b2c-cf-not-found');
export * from './b2c-cf-checkout';
export * from './b2c-cf-profile';
export * from './b2c-cf-cafe-ideal';
export const B2CCFBannerAppStores = () => import('./b2c-cf-banner-app-stores');
export const B2CCFUnauthorized = () => import('./b2c-cf-unauthorized');
export const B2CCFBannerSpotlightWithLoginAction = () => import('./b2c-cf-banner-spotlight-with-login-action');
export * from './b2c-cf-conocer';
export * from './b2c-cf-regalar';
export * from './b2c-cf-aprender';
